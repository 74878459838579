import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import axios from 'axios';
import AlertModal from '../Modal/AlertModal';
import { ActionButton } from '../Modal/css';
import { routes, endpoints } from '../../../utils/constants';
import postErrorLog from '../../../services/errorLog';

const STATUS_ERRORS = [400, 404]; // skip validation errors. usually they are 422.
const ROUTE_WHITELIST = [
  routes.trackShipments,
  routes.userProfile,
];
const recallRequest = {
  currentTryCount: 0,
  maxTried: 1,
};

// const errorLoggingAPI = (config, action, errorString) => {
//   let url = `https://logapierrors.azurewebsites.net/api/LogAPIErrors`;
//   let passingObj = {
//     Url: config.url,
//     callingTime: new Date().toString(),
//     action: action,
//     errorValue: errorString
//   }
//   axios.post(url, passingObj).then(res => {
//     console.log('TrackShipments_Success_response', res)
//   }).catch(error => {
//     console.log('TrackShipments_Error_response', error)
//   })
// }
const ERROR_HANDLING_EXCEPTION = [
  {
    route: ['/'], // all paths
    componentHandlesAPICalls: [
      {
        actions: ['post'],
        url: endpoints.sitecore.switchDivision
      },
    ],
  },
  {
    route: [routes.createShipment],
    componentHandlesAPICalls: [
      {
        actions: ['get'],
        url: endpoints.shipping.quotes
      },
      {
        actions: ['get'],
        url: endpoints.shipping.shipments
      },
    ],
  },
  {
    route: [routes.createUser],
    componentHandlesAPICalls: [
      {
        actions: ['post'],
        url: endpoints.shipping.users
      },
    ],
  },
  {
    route: [routes.viewShipment],
    componentHandlesAPICalls: [
      {
        actions: ['get'],
        url: endpoints.shipping.shipments
      },
    ],
  },
  {
    route: [routes.viewQuote],
    componentHandlesAPICalls: [
      {
        actions: ['get'],
        url: endpoints.shipping.quotes
      },
    ],
  },
];

/**
 * System error component
 * tracks all unhandled thrown errors & server internal errors and shows a modal which redirects to the home page
 * Usage example: <SystemError/>
 */
const SystemError = () => {
  const {
    isError,
    errorMessage,
    closeModal
  } = useSystemError();
  let messageContent;
  try {
    messageContent = errorMessage.response
      && errorMessage.response.data.errors ?
      `${errorMessage.response.data.errors[0].errorMessage.substring(0, 500)}...` : t('SystemErrorBody');
  } catch (error) {
    if (errorMessage && errorMessage.response
      && errorMessage.response.toString().toLowerCase().indexOf('network error') !== -1) {
      messageContent = errorMessage.response;
    } else {
      messageContent = t('SystemErrorBody');
    }
  }

  const header = () => <>
    {' '}
    {
      (messageContent === 'Index and length must refer to a location within the string.\r\nParameter name: length...')
        ? t('Error') : t('SystemErrorHeader')
    }
  </>;
  const content = () => {
    let translatedMessage = messageContent;

    if (
      messageContent === 'An AS/400 exception has occurred....' ||
      messageContent === 'Index and length must refer to a location within the string.\r\nParameter name: length...'
    ) {
      translatedMessage = t('UnableToSubmitShipmentMessage');
    } else if (
      messageContent === 'GF11No match found in given request, Please check and try again...' ||
      messageContent === 'No match found in given request, Please check and try again...'
    ) {
      translatedMessage = t('TrackShipmentServerError');
    }

    return <>{translatedMessage}</>;
  };
  const actions = () => (
    <ActionButton className="active" iconRightArrow onClick={closeModal}>
      {t('Okay')}
    </ActionButton>
  );
  const showSystemError = (messageContent === '002- User not found...'
    || messageContent === 'GetUSRate - This is a non-service location...'
    || messageContent === 'Index was outside the bounds of the array....'
    || messageContent === 'Logged in user does not have access to - ManageAddressBooks...'
    || messageContent === 'Unable to rate - Please call 1-877-726-3329 Customer Service for assistance...'
    || messageContent === 'An AS/400 exception has occurred....'
    || messageContent === 'GF11No match found in given request, Please check and try again...'
    || messageContent === 'No match found in given request, Please check and try again...');

  return (
    <AlertModal
      isOpen={showSystemError ? isError : false}
      onClose={closeModal}
      header={header}
      content={content}
      actions={actions}
      maximizeZIndex
    />
  );
};

const errorHandlingException = (error) => ERROR_HANDLING_EXCEPTION.some((rule) => {
  if (window.location.pathname.includes(rule.route)) {
    return rule.componentHandlesAPICalls.some((apiRule) => (
      error.response && error.response.config && error.response.config.url.includes(apiRule.url)
        ? apiRule.actions.some((actions) => actions === error.response.config.method)
        : null
    ));
  }
  return null;
});

/**
 * Error handler model for a modal
 *
 * @returns {{isError: boolean, closeModal: function}}
 * isError - is true when an error is detected
 * closeModal - callback to set isError to false & navigate to homeUrl
 */
const useSystemError = () => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(String);

  const closeModal = () => {
    setIsError(false);
  };

  useEffect(() => {
    const errorHandler = () => setIsError(true);
    const errorMessageHandler = (error) => {
      setErrorMessage(error);
    };

    if (!ROUTE_WHITELIST.some((r) => window.location.pathname.includes(r))) {
      // Intercept all internal server errors and page not found
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          let errMsgOb = {};
          const errorMessageLocal = error.response.data.errors.map((err) => err.errorMessage).join(',');
          if (error.config && error.config.url.indexOf('CreateErrorLog') === -1) {
            const comingError = error;
            errMsgOb = {
              response: error.toString(),
            };
            const action = window.location.href;
            postErrorLog(error.config, action, comingError.toString(), errorMessageLocal);
            errorMessageHandler(errMsgOb);
          }

          if (
            !errorHandlingException(error) && (
              !error.response
              || error.response.status >= 500
              || STATUS_ERRORS.includes(error.response.status)
            )
          ) {
            // if (!error.response && error.config && error.config.url.indexOf('UILogError') ===-1) {

            // let action = 'Manage-Shipments';
            // if (window.location.href.indexOf('invoices') !== -1) {
            //   action ='Manage-Invoices'
            // }
            // if (window.location.href.indexOf('pickups') !== -1) {
            //   action ='Manage-Pickups'
            // }

            // if (recallRequest.maxTried >= recallRequest.currentTryCount) {
            //   setTimeout(() => {

            //     recallRequest.currentTryCount += 1;
            //     console.log('recalledAPI', recallRequest.currentTryCount);
            //     return axios.request(error.config);
            //    }, 2000);

            // }

            // else {
            recallRequest.currentTryCount = 0;
            errorMessageHandler(error);
            // }
            errorHandler();
          }

          return Promise.reject(error);
        }
      );
    }

    // Subscribe to track all unhandled errors
    window.addEventListener('error', errorHandler);

    // Unsubscribe when the component is unmounted
    return () => window.removeEventListener('error', errorHandler);
  }, []);
  return {
    isError,
    errorMessage,
    closeModal,
  };
};

export default SystemError;
