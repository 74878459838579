/* eslint-disable arrow-body-style */
// based on https://github.com/chriso/validator.js
import validator from 'validator';
import { t } from 'i18next';
import { getTotalWeight, getZip, isAmericanAddress } from './func';
import { Divisions, AMPM, UnitsOfMeasure } from './enums';
import {
  getAbsoluteDifferenceInDays,
  isValidDateRange,
  MAX_DATE_RANGE_DAYS,
} from './dateTime';
import { streetAddressExceptions } from './constants';
import { isInt } from './numbers';
import { formatString, isNullOrWhiteSpace } from './strings';

export const validate = (validation, value, options, errorMessage) => {
  if (!value) {
    return undefined;
  }

  if (options) {
    if (!validation(value, options)) {
      return errorMessage;
    }
  } else if (!validation(value)) {
    return errorMessage;
  }

  return undefined;
};

export const isLength = (length) => (value) => (value && value.length !== length
  ? t('MustBeExactChar', { length })
  : undefined);

export const maxLength = (max) => (value) => (value && value.length > max ? t('MustBeEqualOrLessChar', { max }) : undefined);

export const isRequired = (value) => {
  if (value) {
    return undefined;
  }

  return t('Required');
};

export const hasSomeSelected = (value) => {
  if (value && value.filter((v) => !isNullOrWhiteSpace(v)).length > 0) {
    return undefined;
  }

  return t('MustSelectAtLeastOne');
};

export const anyOneRequiredInGroup = (value, allValues) => {
  if (Object.keys(allValues).length === 0) {
    return t('AtleastOneRequired');
  }

  if (
    (allValues.searchByUserEmail && allValues.searchByUserEmail === '')
    || (allValues.searchByCompany && allValues.searchByCompany === '')
    || (allValues.searchByAccountNumber && allValues.searchByAccountNumber === '')
  ) {
    return t('FieldCannotBeEmpty');
  }

  return undefined;
};

export const isPhone = (value) => {
  if (!value) {
    return undefined;
  }

  if (!validator.isMobilePhone(value, 'en-CA', 'en-US')) {
    return t('InvalidPhoneNumber');
  }

  return undefined;
};

export const isPhoneExtension = (value) => {
  if (!value || value.length === 0) {
    return undefined;
  }

  const regexPattern = /^[0-9]*$/;
  if (!regexPattern.test(value)) {
    return t('InvalidPhoneExtension');
  }

  return undefined;
};

export const isFaxNumber = (value) => {
  if (!value) {
    return undefined;
  }

  if (!validator.isMobilePhone(value, 'en-CA', 'en-US')) {
    return t('InvalidFaxNumber');
  }

  return undefined;
};

export const isEmail = (value) => validate(validator.isEmail, value, null, t('InvalidEmailAddress'));

export const isInRange = (min, max) => (value) => {
  if (value) {
    if (parseInt(value, 10) < min || parseInt(value, 10) > max) {
      return formatString(t('RangeError'), min, max);
    }
  }

  return undefined;
};

export const validateIntInRange = (min, max) => (value) => {
  if (value) {
    if (!isInt(value)) {
      return t('NotAnIntError');
    }

    if (parseInt(value, 10) < min || parseInt(value, 10) > max) {
      return formatString(t('RangeError'), min, max);
    }
  }

  return undefined;
};

export const addressValidate = (address, formData, props) => {
  const streetAddressException = streetAddressExceptions.includes(props.form);
  const requiredNumOfSubstrings = streetAddressException ? 3 : 4;
  const requiredLength = streetAddressException ? 16 : 20;
  const addressSubStrings = address.split(',');
  const requiredPostalLength = isAmericanAddress(address) ? 5 : 6;
  if (
    addressSubStrings.length < requiredNumOfSubstrings
    || address.length < requiredLength
  ) {
    return t('InvalidAddress');
  }

  if (addressSubStrings.length >= requiredNumOfSubstrings && !getZip(address)) {
    return t('MissingPostalCode');
  }

  if (
    addressSubStrings.length >= requiredNumOfSubstrings
    && getZip(address)
    && getZip(address).length < requiredPostalLength
  ) {
    return t('InvalidPostalOrZipCode');
  }

  return undefined;
};

export const hoursValidate = (hours) => {
  if (hours <= 0 || hours > 12) {
    return t('InvalidTime');
  }

  return undefined;
};

export const minutesValidate = (minutes) => {
  if (minutes < 0 || minutes > 59) {
    return t('InvalidTime');
  }

  return undefined;
};

export const isWeekend = (inputDate) => {
  const d = new Date(inputDate);
  d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
  if (d.getDay() === 6 || d.getDay() === 0) {
    return t('IsWeekend');
  }

  return undefined;
};

export const maxChar50 = maxLength(50);
export const maxChar250 = maxLength(250);
export const maxChar255 = maxLength(255);
export const maxChar100 = maxLength(100);
export const maxChar75 = maxLength(75);
export const maxChar25 = maxLength(25);
export const maxChar10 = maxLength(10);
export const maxChar7 = maxLength(7);
export const maxChar6 = maxLength(6);
export const maxChar4 = maxLength(4);
export const maxChar3 = maxLength(3);
export const maxChar2 = maxLength(2);
export const validateHours = validateIntInRange(1, 12);
export const validateMinutes = validateIntInRange(0, 59);
export const oneTo999 = isInRange(1, 999);

export const isValidCanadaUSPostalCode = (value) => {
  if (!isUSZip(value) && !isCANPostal(value)) {
    return t('InvalidPostalOrZipCode');
  }
  return '';
};

export const isUSZip = (value) => {
  const regex = /^\d{5}(-\d{4})?$/;
  return regex.test(value);
};

export const isCANPostal = (value) => {
  const regex = /^([a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d)$/;
  return regex.test(value);
};

export const measurementValidate = (values) => {
  const errors = {};
  if (values.measurementList && values.measurementList.length) {
    const errorsArray = [];
    const {
      measurementList,
      division,
      USShipment
    } = values;
    let { measurementType } = values;
    let multiplier = 1;
    if (typeof measurementType === 'string') {
      switch (measurementType) {
        case UnitsOfMeasure.Feet:
          multiplier = 12;
          measurementType = { value: UnitsOfMeasure.Imperial };
          break;
        case UnitsOfMeasure.Centimeters:
          multiplier = 1;
          measurementType = { value: UnitsOfMeasure.Metric };
          break;
        case UnitsOfMeasure.Meters:
          multiplier = 100;
          measurementType = { value: UnitsOfMeasure.Metric };
          break;
        case UnitsOfMeasure.Inches:
        default:
          multiplier = 1;
          measurementType = { value: UnitsOfMeasure.Imperial };
          break;
      }
    }

    measurementList.forEach((each, index) => {
      const newMeasurementErrors = {};
      const {
        itemLength,
        itemWeight,
        itemWidth,
        itemHeight
      } = each;

      if (itemWeight) {
        // weight
        const curr = Number(getTotalWeight(measurementList));
        if (
          measurementType.value === UnitsOfMeasure.Metric
          && curr > 4535
          && division === Divisions.Sameday.name
        ) {
          newMeasurementErrors.itemWeight = `${t('total item weight cannot exceed')} 4,535 kg`;
          errorsArray[index] = newMeasurementErrors;
        } else if (curr > 9999 && division === Divisions.Sameday.name) {
          newMeasurementErrors.itemWeight = `${t('total item weight cannot exceed')} 9,999 lbs`;
          errorsArray[index] = newMeasurementErrors;
        }

        if (
          measurementType.value === UnitsOfMeasure.Metric
          && curr > 9071
          && division === Divisions.Freight.name
        ) {
          newMeasurementErrors.itemWeight = `${t('total item weight cannot exceed')} 9,071 kg`;
          errorsArray[index] = newMeasurementErrors;
        } else if (
          USShipment
          && curr > 19999
          && division === Divisions.Freight.name
        ) {
          newMeasurementErrors.itemWeight = `${t('total item weight cannot exceed')} 19,999 lbs`;
          errorsArray[index] = newMeasurementErrors;
        } else if (curr > 19999 && division === Divisions.Freight.name) {
          newMeasurementErrors.itemWeight = `${t('total item weight cannot exceed')} 19,999 lbs`;
          errorsArray[index] = newMeasurementErrors;
        }
      }

      // for LTL or CS Auth and Non-Auth, and also for US and Non-US shipment/quote/pickup
      if (itemWeight) {
        // weight
        if (measurementType.value === UnitsOfMeasure.Imperial) {
          // length
          if (itemLength && itemLength * multiplier > 393) {
            newMeasurementErrors.itemLength = t('item length cannot exceed')
              + (multiplier === 1 ? ' 393 inches' : ' 32.75 feet');
            errorsArray[index] = newMeasurementErrors;
          }

          // width
          if (itemWidth && itemWidth * multiplier > 96) {
            newMeasurementErrors.itemWidth = t('item width cannot exceed')
              + (multiplier === 1 ? ' 96 inches' : ' 8 feet');
            errorsArray[index] = newMeasurementErrors;
          }

          // height
          if (itemHeight && itemHeight * multiplier > 96) {
            newMeasurementErrors.itemHeight = t('item height cannot exceed')
              + (multiplier === 1 ? ' 96 inches' : ' 8 feet');
            errorsArray[index] = newMeasurementErrors;
          }
        } else if (measurementType.value === UnitsOfMeasure.Metric) {
          // length
          if (itemLength && itemLength * multiplier > 998) {
            newMeasurementErrors.itemLength = t('item length cannot exceed')
              + (multiplier === 1 ? ' 998 cm' : ' 9.98 m');
            errorsArray[index] = newMeasurementErrors;
          }

          // width
          if (itemWidth && itemWidth * multiplier > 243) {
            newMeasurementErrors.itemWidth = t('item width cannot exceed')
              + (multiplier === 1 ? ' 243 cm' : ' 2.43 m');
            errorsArray[index] = newMeasurementErrors;
          }

          // height
          if (itemHeight && itemHeight * multiplier > 243) {
            newMeasurementErrors.itemHeight = t('item height cannot exceed')
              + (multiplier === 1 ? ' 243 cm' : ' 2.43 m');
            errorsArray[index] = newMeasurementErrors;
          }
        }
      }
      if (errorsArray.length) {
        errors.measurementList = errorsArray;
      }
    });
  }
  return errors;
};

export const closingTimeValidate = (value, formData) => {
  const {
    closingTime,
    readyTime
  } = formData;
  let closingHour = (Number(closingTime.hours) % 12) + closingTime.minutes / 60;
  let readyHour = (Number(readyTime.hours) % 12) + readyTime.minutes / 60;
  if (closingTime && closingTime.amOrPm === AMPM.PM) {
    closingHour += 12;
  }
  if (readyTime && readyTime.amOrPm === AMPM.PM) {
    readyHour += 12;
  }

  if (
    closingTime
    && closingTime.hours
    && readyTime
    && readyTime.hours
    && closingHour - readyHour < 2
  ) {
    return t('AtLeastTwoHours');
  }

  return '';
};
export const dateRangeValidate = (values, allValues) => {
  if (
    !isValidDateRange({
      fromDateString: allValues.filterFromDate,
      toDateString: allValues.filterToDate,
    })
  ) {
    return t('InvalidDateRange');
  }

  if (
    getAbsoluteDifferenceInDays({
      fromDateString: allValues.filterFromDate,
      toDateString: allValues.filterToDate,
    }) > MAX_DATE_RANGE_DAYS
  ) {
    return t('DateRangeExceeded', { maxDays: MAX_DATE_RANGE_DAYS });
  }

  return undefined;
};

export const listCompleted = (list) => {
  return list && !list.some((item) => !item.completed);
};

export const isValidTextEntry = (value) => {
  const regex = /[^a-zA-Z\d\s,:=.]/;
  if (regex.test(value)) {
    return t('InvalidCharacters');
  }

  return undefined;
};
export const isValidDescription = (value) => {
  const regex = /0*(^[0][a-zA-Z\d\s,:=.]*|^0)/g;
  if (regex.test(value) && value.length === 1) {
    return t('InvalidCharacters');
  }

  return undefined;
};

export const isValidDigitInput = (value) => {
  const regex = /0*(^[0][1-9]*|^0)/g;
  if (regex.test(value) && value.length === 1) {
    return t('InvalidCharacters');
  }

  return undefined;
};

export const isValidTextAreaEntry = (value) => {
  const regex = /[^a-zA-Z\d\s,:=.@#$&%]/;
  if (regex.test(value)) {
    return t('InvalidCharacters');
  }

  return undefined;
};
