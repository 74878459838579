import React, { Fragment } from 'react';
import { t } from 'i18next';
import { Field, change } from 'redux-form';
import {
  Table, Thead, Tbody, Tr, Td
} from 'react-super-responsive-table';
import PaginationControls from './PaginationControls';
import RenderField from '../RenderField';
import {
  TableContainer,
  TableHeader,
  ResponsiveTable, TableHeaderLabelContainer, TableHeaderLabel, ArrowDownIcon
} from './css';
import ColumnTypes from './constants';

let isOrangeFirst = false;

class SortableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      dataPerPage: 10,
      totalPages: 0,
    };
  }

  handleSort = (id, columnType) => {
    isOrangeFirst = !isOrangeFirst;
    const getCurrencyValue = (currencyString) =>
      // Assuming value is "currencyType 1234", get amount "1234"...
      Number(parseFloat(currencyString.substr(currencyString.indexOf(' ') + 1)));

    const sortByImage = (a, b) => {
      const imageA = a.isAdmin.props.iconSrc.toLowerCase();
      const imageB = b.isAdmin.props.iconSrc.toLowerCase();
      if (imageA < imageB) {
        return -1;
      }
      if (imageA > imageB) {
        return 1;
      }
      return 0;
    };

    const customSort = ({ prev, a, b }) => (
      // Historical algorithm...
      /* eslint-disable no-nested-ternary */
      prev
        ? a < b
          ? 1
          : b < a
            ? -1
            : 0
        : a > b
          ? 1
          : b > a
            ? -1
            : 0
    );
    /* eslint-enable no-nested-ternary */

    this.setState((prev) => ({
      [id]: !prev[id],
      data: prev.data.sort((a, b) => {
        if (id === 'isAdmin') {
          if (isOrangeFirst) {
            return sortByImage(a, b);
          }
          return sortByImage(b, a);
        }

        let aCopy = '';
        let bCopy = '';
        if (a[id] !== undefined && b[id] !== undefined) {
          aCopy = a[id].toLowerCase();
          bCopy = b[id].toLowerCase();

          if (columnType && columnType === ColumnTypes.Currency) {
            aCopy = getCurrencyValue(a[id]);
            bCopy = getCurrencyValue(b[id]);
          }
        }
        return customSort({ prev: prev[id], a: aCopy, b: bCopy });
      })
    }));
    this.setCurrentPage(1);
  };

  getTotalPages = () => {
    const { dataPerPage, data } = this.state;
    const totalPages = [];
    for (let i = 1; i <= Math.ceil(data.length / dataPerPage); i++) {
      totalPages.push(i);
    }
    this.setState((prevState) => ({
      totalPages: totalPages.length,
      currentPage: prevState.currentPage > totalPages.length ? totalPages.length : prevState.currentPage
    })
    );
  };

  renderRows = (eachRow) => {
    const { columns } = this.props;
    return columns.map((column, key) =>
      column.field !== 'separateRow' &&
      <Td key={key}>{eachRow[column.field]}</Td>
    );
  };

  setCurrentPage = (pageNum) => {
    const { dispatch, formName } = this.props;
    if (dispatch) {
      dispatch(change(formName, 'checked', []));
      dispatch(change(formName, 'checkAll', ['']));
    }
    this.setState({
      currentPage: pageNum
    });
  };

  componentDidMount() {
    const { data } = this.props;
    this.setState({ data }, () => this.getTotalPages());
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.setState({ data }, () => this.getTotalPages());
    }
  }

  checkAll = (e) => {
    const { dispatch, formName } = this.props;
    const { currentPage, dataPerPage } = this.state;
    const currentPageIndexed = currentPage - 1;
    const currentStart = (currentPageIndexed) * dataPerPage;
    const check = e.length === 2 ? ['', ' '] : [''];
    for (let i = currentStart; i < (currentStart + dataPerPage); i++) {
      dispatch(change(formName, `checked.${i}`, check));
    }
  };

  render() {
    const {
      data, dataPerPage, currentPage, totalPages
    } = this.state;
    const { columns, hidePagination } = this.props;

    const visibleColumns = columns.filter((c) => c.field !== 'separateRow');

    const indexOfLastItem = currentPage * dataPerPage;
    const indexOfFirstItem = indexOfLastItem - dataPerPage;
    const currentDataItems = data.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <>
        <TableContainer>
          <ResponsiveTable>
            <Table className="table-responsive-lg">
              <Thead>
                <Tr>
                  {visibleColumns &&
                    visibleColumns.map((column) => {
                      if (column.field === 'check') {
                        return (
                          <TableHeader key={column.field}>
                            <Field
                              name="checkAll"
                              type="checkboxes"
                              data={[' ']}
                              multiple
                              onChange={(e) => this.checkAll(e)}
                              component={RenderField}
                            />
                          </TableHeader>
                        );
                      }
                      return (
                        <TableHeader
                          key={column.field}
                          onClick={column.sort ? () => this.handleSort(column.field, column.type) : null}>
                          <TableHeaderLabelContainer>
                            <TableHeaderLabel field={`${t(column.label)}`} />
                            {column.sort && <ArrowDownIcon />}
                          </TableHeaderLabelContainer>
                        </TableHeader>
                      );
                    }
                    )}
                </Tr>
              </Thead>
              <Tbody>
                {currentDataItems.map((each, index) => (
                  <Fragment key={index}>
                    <Tr>
                      {this.renderRows(each)}
                    </Tr>
                    {each.separateRow && <Tr key={100 + index}>{/* 100+index is to avoid key duplication in case a row is seperateRow */}
                      <Td colSpan={visibleColumns.length}>
                        {each.separateRow}
                      </Td>
                    </Tr>}
                  </Fragment>))}
              </Tbody>
            </Table>
          </ResponsiveTable>

          {/* --- Paginnation Controls --- */}
          {!hidePagination && currentDataItems.length > 0 &&
            PaginationControls({
              currentPage,
              totalPages,
              setCurrentPageFn: this.setCurrentPage
            })}
        </TableContainer>
      </>
    );
  }
}

export default SortableTable;
