import axios from 'axios';
import { t } from 'i18next';
import { SubmissionError } from 'redux-form';
import { endpoints } from '../../utils/constants';
import {
  getAddress, getZip, getCity, getState, getCountry, downloadFile
} from '../../utils/func';
import { digitOnly } from '../../utils/mask';
import handleDeliveryType from '../../utils/deliveryAddress';
import {
  DeliveryTypes, QuoteTypes, PaymentMethods, SamedayServiceType, PrefillNumberTypes, BillToMethods, SamedayShipmentType
} from '../../utils/enums';

const measurementLists = (measurementList, measurementType) => {
  const measurementListsToApi = measurementList.map((each) => {
    if (each.itemDangerous) {
      return {
        measurementType,
        weight: Number(each.itemWeight),
        length: Number(each.itemLength),
        width: Number(each.itemWidth),
        height: Number(each.itemHeight),
        pieces: Number(each.itemCount),
        description: each.itemDescription,
        isDangerousGoods: each.itemDangerous,
        unNumber: each.unNumber,
        primaryClass: each.primeClass,
        subsidaryClass: each.secondClass,
        packagingGroup: each.packagingGroup ? each.packagingGroup.value : null,
        isToxicByInhalation: each.toxic ? each.toxic : false,
        quantity: each.totalQuantity,
        quantityType: each.totalQuantityUnits,
        shippingName: each.shippingName,
        numOfPackagesRequiringLabels: Number(each.numberPackRequireLabel),
        isNEQ: each.neq ? each.neq : false,
        freightClass: each.itemFreightClass,
        nmfc: each.itemNMFC
      };
    }

    return {
      measurementType,
      weight: Number(each.itemWeight),
      length: Number(each.itemLength),
      width: Number(each.itemWidth),
      height: Number(each.itemHeight),
      pieces: Number(each.itemCount),
      description: each.itemDescription,
      freightClass: each.itemFreightClass,
      nmfc: each.itemNMFC
    };
  });

  return measurementListsToApi;
};

const accessorialsLists = (accessorials) => (accessorials ? Object.values(accessorials).filter((acc) => acc.selected.length > 1).map((each) => {
  const { selected, value, currency } = each;
  if (value && currency) {
    return {
      accessorialId: selected[1].accessorialId ? selected[1].accessorialId : 1,
      code: selected[1].code,
      value: Number(value),
      currencyCode: currency
    };
  }
  return {
    accessorialId: selected[1].accessorialId ? selected[1].accessorialId : 1,
    code: selected[1].code
  };
}) : '');

export const getAccessorials = ({
  isAuthenticated, division, updateState, USQuote, billTo
}) => axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.accessorials}/${division}${USQuote ? '/customer' : ''}`,
  {
    headers: {
      accountNumber: isAuthenticated ? billTo : '999998'
    }
  }).then((res) => {
  if (res.status === 200) {
    // if (!USQuote) {
    //   const resData = res.data;
    //   const domesticAccessorialWithoutInBond = resData.filter((item) => item.accessorialId !== 300
    //       || item.accessorialId !== 47 || item.code !== 'INBOND');
    //   updateState('accList', domesticAccessorialWithoutInBond);
    // } else {
    updateState('accList', res.data);
    // }
  }
});

export const getServiceQuotes = ({
  putReduxServiceLevelQuotes, putReduxServiceLevelCode, formData, prevStep, openModal
}) => {
  const {
    deliveryType,
    division,
    paymentMethod,
    shipperContactName,
    shipperCompanyName,
    shipperAddress,
    shipperAddressAdditional,
    shipperEmail,
    shipperPhoneNumber,
    shipperPhoneNumberExt,
    shipmentType,
    consigneeAddressAdditional,
    consigneeEmail,
    consigneePhoneNumber,
    consigneeAddress,
    consigneeContactName,
    consigneeCompanyName,
    consigneePhoneNumberExt,
    measurementType,
    measurementList,
    accessorials,
    quoteDest,
    billTo,
    numberTypeSelected,
    quoteOrShipmentNumber
  } = formData;

  const shipperAddress1 = getAddress(shipperAddress);
  const shipperCity = getCity(shipperAddress);
  const shipperState = getState(shipperAddress);
  const shipperZip = getZip(shipperAddress);
  const shipperCountry = getCountry(shipperAddress);

  const consigneeAddress1 = getAddress(consigneeAddress);
  const consigneeCity = getCity(consigneeAddress);
  const consigneeState = getState(consigneeAddress);
  const consigneeZip = getZip(consigneeAddress);
  const consigneeCountry = getCountry(consigneeAddress);

  const formValueToApi = {
    sourceType: 'CS',
    serviceType: deliveryType.name === DeliveryTypes.Residential ? SamedayServiceType.Residential : SamedayServiceType.Commercial,
    paymentType: paymentMethod.name.split(' ').join(''),
    language: 'EN',
    division,
    requestor: {
      companyName: shipperCompanyName,
      contactName: shipperContactName,
      address1: shipperAddress1,
      address2: shipperAddressAdditional,
      city: shipperCity,
      provinceCode: shipperState,
      countryCode: shipperCountry,
      postalCode: shipperZip,
      contactPhoneNumber: digitOnly(shipperPhoneNumber),
      contactPhoneNumberExt: shipperPhoneNumberExt,
      contactEmail: quoteDest
    },
    from: {
      companyName: shipperCompanyName,
      contactName: shipperContactName,
      address1: shipperAddress1,
      address2: shipperAddressAdditional,
      city: shipperCity,
      provinceCode: shipperState,
      countryCode: shipperCountry,
      postalCode: shipperZip,
      contactPhoneNumber: digitOnly(shipperPhoneNumber),
      contactPhoneNumberExt: shipperPhoneNumberExt,
      contactEmail: shipperEmail
    },
    to: {
      companyName: consigneeCompanyName,
      contactName: consigneeContactName,
      address1: consigneeAddress1,
      address2: consigneeAddressAdditional,
      city: consigneeCity,
      provinceCode: consigneeState,
      countryCode: consigneeCountry,
      postalCode: consigneeZip,
      contactPhoneNumber: digitOnly(consigneePhoneNumber),
      contactPhoneNumberExt: consigneePhoneNumberExt,
      contactEmail: consigneeEmail
    },
    shipmentItems: measurementLists(measurementList, measurementType.value),
    accessorials: accessorialsLists(accessorials),
    accountNumber: billTo,
    measurementType: measurementType.value,
    shipmentType,
    quoteNumber: numberTypeSelected.value === PrefillNumberTypes.quote ? quoteOrShipmentNumber : undefined
  };

  return axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.serviceLevel}`, formValueToApi).then((res) => {
    if (res.status === 200) {
      putReduxServiceLevelQuotes(res.data.serviceLevels);
      putReduxServiceLevelCode(handleDeliveryType(deliveryType, res));
    }
  }).catch((err) => {
    if (err.response.data.statusCode === 500) {
      openModal({ serviceLevelError: 'serviceLevelError', serviceLevelErrorArray: err.response.data });
      prevStep();
    }
  });
};

const parseAttachedDocuments = (attachedDocuments, formValueToApi) => {
  if (attachedDocuments) {
    formValueToApi.uploadFiles = [];
    Object.keys(attachedDocuments).forEach((key) => {
      formValueToApi.uploadFiles.push({
        fileName: attachedDocuments[key].name,
        fileDataAsBase64: attachedDocuments[key].file,
      });
    });
  }
};

export const submitShipment = ({
  // eslint-disable-next-line no-unused-vars
  serviceLevelCode, formData, updateState, prevStep
}) => {
  const {
    deliveryType,
    division,
    paymentMethod,
    shipperContactName,
    shipperCompanyName,
    shipperAddress,
    shipperAddressAdditional,
    shipperEmail,
    shipperPhoneNumber,
    shipperPhoneNumberExt,
    consigneeAddressAdditional,
    consigneeEmail,
    consigneePhoneNumber,
    consigneeAddress,
    consigneeContactName,
    consigneeCompanyName,
    consigneePhoneNumberExt,
    measurementType,
    measurementList,
    accessorials,
    quoteDest,
    pickupDate,
    emergencyPhone,
    erapReference,
    erapPhone,
    referenceNumber,
    poNumber,
    specialInstructions,
    readyTime,
    closingTime,
    shipmentType,
    accessByTrailer,
    regularScheduledPickup,
    attachedDocuments,
    billingAddressAdditional,
    billingEmail,
    billingPhoneNumber,
    billingAddress,
    billingContactName,
    billingCompanyName,
    billingPhoneNumberExt,
    gstNumber,
  } = formData;

  const shipperAddress1 = getAddress(shipperAddress);
  const shipperCity = getCity(shipperAddress);
  const shipperState = getState(shipperAddress);
  const shipperZip = getZip(shipperAddress);
  const shipperCountry = getCountry(shipperAddress);

  const consigneeAddress1 = getAddress(consigneeAddress);
  const consigneeCity = getCity(consigneeAddress);
  const consigneeState = getState(consigneeAddress);
  const consigneeZip = getZip(consigneeAddress);
  const consigneeCountry = getCountry(consigneeAddress);

  const formValueToApi = {
    serviceLevelCode,
    serviceType: deliveryType.name === DeliveryTypes.Residential ? SamedayServiceType.Residential : SamedayServiceType.Commercial,
    paymentType: paymentMethod.name.split(' ').join(''),
    language: 'EN',
    shipmentType: shipmentType ? shipmentType : SamedayShipmentType.Regular,
    division,
    from: {
      companyName: shipperCompanyName,
      contactName: shipperContactName,
      address1: shipperAddress1,
      address2: shipperAddressAdditional,
      city: shipperCity,
      provinceCode: shipperState,
      countryCode: shipperCountry,
      postalCode: shipperZip,
      contactPhoneNumber: digitOnly(shipperPhoneNumber),
      contactPhoneNumberExt: shipperPhoneNumberExt,
      contactEmail: shipperEmail
    },
    to: {
      companyName: consigneeCompanyName,
      contactName: consigneeContactName,
      address1: consigneeAddress1,
      address2: consigneeAddressAdditional,
      city: consigneeCity,
      provinceCode: consigneeState,
      countryCode: consigneeCountry,
      postalCode: consigneeZip,
      contactPhoneNumber: digitOnly(consigneePhoneNumber),
      contactPhoneNumberExt: consigneePhoneNumberExt,
      contactEmail: consigneeEmail
    },
    thirdPartyDetails: paymentMethod.name === PaymentMethods.ThirdParty ? {
      companyName: billingCompanyName,
      contactName: billingContactName,
      address1: getAddress(billingAddress),
      address2: billingAddressAdditional,
      city: getCity(billingAddress),
      provinceCode: getState(billingAddress),
      countryCode: getCountry(billingAddress),
      postalCode: getZip(billingAddress),
      contactPhoneNumber: digitOnly(billingPhoneNumber),
      contactPhoneNumberExt: billingPhoneNumberExt,
      contactEmail: billingEmail
    } : undefined,
    gstNumber,
    shipmentItems: measurementLists(measurementList, measurementType.value),
    accessorials: accessorialsLists(accessorials),
    confirmationEmail: quoteDest,
    measurementType: measurementType.value,
    specialInstructions,
    isAllowAccessByTrailer: accessByTrailer.value,
    hasRegularPickups: regularScheduledPickup.value,
    pickupDetails: regularScheduledPickup.value ? undefined : {
      pickupDate,
      readyTime: `${readyTime.hours.length < 2 ?
        `0${readyTime.hours}`
        : readyTime.hours}:${readyTime.minutes.length < 2 ? `0${readyTime.minutes}` : readyTime.minutes} ${readyTime.amOrPm}`,
      closingTime: `${closingTime.hours.length < 2
        ? `0${closingTime.hours}`
        : closingTime.hours}:${closingTime.minutes.length < 2 ? `0${closingTime.minutes}` : closingTime.minutes} ${closingTime.amOrPm}`,
    },
    referenceNumber,
    poNumber,
    emergencyPhoneNumber: emergencyPhone ? digitOnly(emergencyPhone) : emergencyPhone,
    erapReferenceNumber: erapReference,
    erapPhoneNumber: erapPhone ? digitOnly(erapPhone) : erapPhone,
  };

  parseAttachedDocuments(attachedDocuments, formValueToApi);

  return axios.post(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipments}`, formValueToApi).then((res) => {
    if (res.status === 200 || res.status === 201) {
      updateState('data', {
        probillNumber: res.data.probillNumber,
        orderNumber: res.data.orderNumber
      });
      window.scrollTo(0, 0);
    }
  }).catch(() => {
    throw new SubmissionError({});
  });
};

export const formDataToApi = (formData, userId, serviceLvlCode, userEmail) => {
  const {
    quoteType,
    usFromAddress,
    usToAddress,
    deliveryType,
    division,
    paymentMethod,
    shipperContactName,
    shipperCompanyName,
    shipperAddress,
    shipperAddressAdditional,
    shipperEmail,
    shipperPhoneNumber,
    shipperPhoneNumberExt,
    consigneeAddressAdditional,
    consigneeEmail,
    consigneePhoneNumber,
    consigneeAddress,
    consigneeContactName,
    consigneeCompanyName,
    consigneePhoneNumberExt,
    measurementType,
    measurementList,
    accessorials,
    quoteDest,
    tariffSurcharge,
    billTo,
    shipmentType = SamedayShipmentType.Regular,
    USShipment,
    pickupDate,
    readyTime,
    closingTime,
    accessByTrailer,
    sendConsigneeInfo,
    regularScheduledPickup,
    attachedDocuments,
    billingAddressAdditional,
    billingEmail,
    billingPhoneNumber,
    billingAddress,
    billingContactName,
    billingCompanyName,
    billingPhoneNumberExt,
    gstNumber,
    thirdPartyAccountNumber,
    referenceNumber,
    poNumber,
    specialInstructions,
    emergencyPhone,
    erapReference,
    erapPhone,
    shipmentId,
    numberTypeSelected,
    quoteOrShipmentNumber,
    billToMethod
  } = formData;

  const USQuote = quoteType && quoteType.name === QuoteTypes.USQuote;

  let shipperAddress1;
  let shipperCity;
  let shipperState;
  let shipperZip;
  let shipperCountry;

  let consigneeAddress1;
  let consigneeCity;
  let consigneeState;
  let consigneeZip;
  let consigneeCountry;

  if (USQuote) {
    shipperCity = usFromAddress.cityName;
    shipperState = usFromAddress.provinceCode;
    shipperZip = usFromAddress.postalCode;
    shipperCountry = usFromAddress.countryCode;

    consigneeCity = usToAddress.cityName;
    consigneeState = usToAddress.provinceCode;
    consigneeZip = usToAddress.postalCode;
    consigneeCountry = usToAddress.countryCode;
  } else {
    shipperAddress1 = getAddress(shipperAddress);
    shipperCity = getCity(shipperAddress);
    shipperState = getState(shipperAddress);
    shipperZip = getZip(shipperAddress);
    shipperCountry = getCountry(shipperAddress);

    consigneeAddress1 = getAddress(consigneeAddress);
    consigneeCity = getCity(consigneeAddress);
    consigneeState = getState(consigneeAddress);
    consigneeZip = getZip(consigneeAddress);
    consigneeCountry = getCountry(consigneeAddress);
  }
  const formValueToApi = {
    shipmentId,
    confirmationEmail: quoteDest,
    measurementType: measurementType.value,
    // userId: userId,
    userId: +userId,
    serviceLevelCode: serviceLvlCode,
    serviceType: deliveryType.name === DeliveryTypes.Residential ? SamedayServiceType.Residential : SamedayServiceType.Commercial,
    paymentType: paymentMethod.name.split(' ').join(''),
    language: 'EN',
    accountNumber: billToMethod !== BillToMethods.enterBillTo ? billTo : thirdPartyAccountNumber,
    createByUserId: userId,
    createByUserEmail: userEmail,
    sendEmailToConsignee: sendConsigneeInfo,
    division,
    discountPercentage:
      tariffSurcharge && tariffSurcharge.discountPercentage
        ? tariffSurcharge.discountPercentage
        : undefined,
    minimumRateAmount:
      tariffSurcharge
        && tariffSurcharge.minimumRateAmount
        && tariffSurcharge.minimumRateAmount.value
        ? tariffSurcharge.minimumRateAmount.value
        : undefined,
    requestor: {
      companyName: shipperCompanyName,
      contactName: shipperContactName,
      address1: shipperAddress1,
      address2: shipperAddressAdditional,
      city: shipperCity,
      provinceCode: shipperState,
      countryCode: shipperCountry,
      postalCode: shipperZip,
      contactPhoneNumber: digitOnly(shipperPhoneNumber),
      contactPhoneNumberExt: shipperPhoneNumberExt,
      contactEmail: quoteDest
    },
    from: {
      companyName: shipperCompanyName,
      contactName: shipperContactName,
      address1: shipperAddress1,
      address2: shipperAddressAdditional,
      city: shipperCity,
      provinceCode: shipperState,
      countryCode: shipperCountry,
      postalCode: shipperZip,
      contactPhoneNumber: digitOnly(shipperPhoneNumber),
      contactPhoneNumberExt: shipperPhoneNumberExt,
      contactEmail: shipperEmail
    },
    to: {
      companyName: consigneeCompanyName,
      contactName: consigneeContactName,
      address1: consigneeAddress1,
      address2: consigneeAddressAdditional,
      city: consigneeCity,
      provinceCode: consigneeState,
      countryCode: consigneeCountry,
      postalCode: consigneeZip,
      contactPhoneNumber: digitOnly(consigneePhoneNumber),
      contactPhoneNumberExt: consigneePhoneNumberExt,
      contactEmail: consigneeEmail
    },
    thirdPartyDetails: paymentMethod.name === PaymentMethods.ThirdParty && billToMethod !== BillToMethods.pickBillTo ? {
      companyName: billingCompanyName,
      contactName: billingContactName,
      address1: getAddress(billingAddress),
      address2: billingAddressAdditional,
      city: getCity(billingAddress),
      provinceCode: getState(billingAddress),
      countryCode: getCountry(billingAddress),
      postalCode: getZip(billingAddress),
      contactPhoneNumber: digitOnly(billingPhoneNumber),
      contactPhoneNumberExt: billingPhoneNumberExt,
      contactEmail: billingEmail
    } : undefined,
    thirdPartyAccountNumber,
    gstNumber,
    shipmentItems: measurementLists(measurementList, measurementType.value),
    accessorials: accessorialsLists(accessorials),
    shipmentType,
    USShipment,
    isAllowAccessByTrailer: accessByTrailer.value,
    hasRegularPickups: regularScheduledPickup.value,
    pickupDetails: regularScheduledPickup.value ? undefined : {
      pickupDate,
      readyTime: `${readyTime.hours.length < 2
        ? `0${readyTime.hours}`
        : readyTime.hours}:${readyTime.minutes.length < 2 ? `0${readyTime.minutes}` : readyTime.minutes} ${readyTime.amOrPm}`,
      closingTime: `${closingTime.hours.length < 2
        ? `0${closingTime.hours}`
        : closingTime.hours}:${closingTime.minutes.length < 2 ? `0${closingTime.minutes}` : closingTime.minutes} ${closingTime.amOrPm}`,
    },
    specialInstructions,
    referenceNumber,
    poNumber,
    emergencyPhoneNumber: emergencyPhone ? digitOnly(emergencyPhone) : emergencyPhone,
    erapReferenceNumber: erapReference,
    erapPhoneNumber: erapPhone ? digitOnly(erapPhone) : erapPhone,
    quoteNumber: numberTypeSelected.value === PrefillNumberTypes.quote ? quoteOrShipmentNumber : undefined
  };

  parseAttachedDocuments(attachedDocuments, formValueToApi);

  return formValueToApi;
};

export const BOLcall = ({
  probillNumber, division, emailAddress, userId
}) => {
  axios.get(`${t('ApiEndPoint')}${t('ShippingApiVirtualFolder')}${endpoints.shipping.shipmentDocuments}/${division}/${probillNumber}/bol`, {
    params: {
      emailAddress,
      userId
    },
  }).then((res) => {
    if (res.status === 200) {
      downloadFile({ dataAsBase64: res.data.fileDataAsBase64, mimeType: res.data.mimeType, filename: res.data.fileName });
    }
  })
    .catch((error) => {
      if (error) {
        console.error('error is', error);
      }
    });
};
